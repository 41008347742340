.comment_wrapper{
  background: #fff;
  border-radius: 8px;
  margin: 20px 0;
  padding: 20px 20px 20px 48px;
  word-break: break-all;
  white-space: normal;
}

.comment_header{
  display: flex;
  align-items: center;
}

.comment_name{
  font-size: 16px;
  color: black;
}

.comment_content{
  margin-top: 20px;
}