.header{
  background: #323a44;
}

.header_wrapper{
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_logo{
  width: 40px;
}

.header_logo_wrapper{
  display: flex;
  align-items: center;
}

.header_logo_title{
  color: #d6f4ff;
  margin-left: 10px;
  font-size: 32px;
}

.header_menu{
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: 150px;
  height: 100%;
  list-style: none;
  color: white;
}

.header_user_img_wrapper{
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 100%;
  position: relative;
}

.header_user_img{
  width: 20px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.header_user_menu{
  opacity: 0;
  pointer-events: none;
  width: 100%;
  list-style: none;
  background: white;
  padding: 10px 0px;
  margin: unset;
  transition: 0.2s all;
  z-index: 1;
}

.header_user_menu_item{
  padding: 10px 10px;
  cursor: pointer;
  transition: 0.3s all;
  font-size: 14px;
  &:hover{
    background: #00d3ff42;
  }
}

.header_user_wrapper{
  display: flex;
  align-items: center;
  position: relative;
}

.header_user_login{
  margin-left: 10px;
  color: white;
  font-size: 14px;
}

.header_user_name{
  margin-left: 10px;
  font-size: 16px;
}

.header_user_menu_button{
  cursor: pointer;
  width: 20px;
  height: 30px;
  position: relative;
  &:before{
    content: '';
    width: 8px;
    height: 1px;
    background: white;
    position: absolute;
    right: 6px;
    top: 15px;
    transform: rotate(45deg);
  }
  &:after{
    content: '';
    width: 8px;
    height: 1px;
    background: white;
    position: absolute;
    right: 0px;
    top: 15px;
    transform: rotate(-45deg)
  }
}

.header_language{
  display: flex;
  color: white;
}

.header_language_item{
  color: white;
  margin: 0px 5px;
  padding: 1px 4px;
  font-size: 14px;
  cursor: pointer;
}

.language_active{
  background: #617083;
}

.divider{
  height: 1px;
  background: #b6b6b6;
  //margin: 5px 0px;
}

.active{
  opacity: 1;
  pointer-events: unset;
}

.header_menu_item{
  line-height: 50px;
  padding: 0px 10px;
  transition: 0.3s all;
  cursor: pointer;
  font-size: 14px;
  color: white;
  text-decoration: none;
  &:hover{
    background: #617083;
  }
}

.header_overlay{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: none;
}

.overlay_active{
  display: block;
}

.active_menu_item{
  background: #617083;
}
