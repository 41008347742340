.loader{
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.6);
  z-index: -1;
  opacity: 0;
  transition: 0.3s opacity;
}

.active{
  opacity: 1;
  z-index: 1001;
}

.spinner{
  font-size: 100px;
  color: white;
}