.help_desc{
  font-size: 14px;
  color: #4c515c;
}

.help_item{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  &:last-child{
      margin-bottom: unset;
  }
}

.help_icon{
  font-size: 30px;
  margin: 0 25px;
  color: #4c515c;
}

.help_items_wrapper{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.help_item_desc_wrapper{
  display: flex;
  flex-direction: column;
}

.help_item_desc{
  font-size: 14px;
  color: #4c515c;
}

.ant-modal-title{
  font-size: 20px !important;
}