.themes{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.themes_header{
  display: flex;
  align-items: center;
}

.themes_header_title{
  font-size: 24px;
  font-weight: 500;
}

.themes_select{
  width: 250px;
  margin-left: 20px;
}

.themes_header_button{
  margin-left: 20px;
}

.themes_table_button{
  position: relative;
  margin-right: 10px;
  &:hover{
    .themes_table_button_tooltip{
      opacity: 1;
      &:before{
        opacity: 1;
      }
    }
  }
}

.themes_table_button_tooltip{
  font-size: 14px;
  position: absolute;
  top: -36px;
  left: -13px;
  background: #0000006e;
  color: white;
  border-radius: 4px;
  padding: 4px;
  opacity: 0;
  transition: 0.3s all;
  pointer-events: none;
  &:before{
    content: '';
    opacity: 0;
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #0000006e;
    bottom: -5px;
    left: 25px;
    transition: 0.3s all;
    pointer-events: none;
  }
}

.themes_table_type{
  background: #f6f6f7;
  width: 30px;
  height: 16px;
  line-height: 16px;
  font-size: 10px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  &:hover{
    .themes_table_type_tooltip{
      opacity: 1;
      &:before{
        opacity: 1;
      }
    }
  }
}

.themes_table_type_tooltip{
  position: absolute;
  width: 200px;
  color: white;
  background: #0000006e;
  border-radius: 4px;
  z-index: 1;
  top: -24px;
  left: -20px;
  opacity: 0;
  transition: 0.3s all;
  pointer-events: none;
  padding: 2px;
  &:before{
    content: '';
    opacity: 0;
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #0000006e;
    bottom: -5px;
    left: 30px;
    transition: 0.3s all;
    pointer-events: none;
    transition: 0.3s all;
    pointer-events: none;
  }
}

.themes_table_name{
  color: #4870b7;
  transition: 0.3s all;
  cursor: pointer;
  &:hover{
    color: #4491f5;
  }
}
