.modal_input{
  //margin: 10px 0px;
}

.ant-input-group-addon{
  width: 100px !important;
}

.form_theme_title{
  font-size: 16px;
}